@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url(~tailwindcss/base.css);

@import url(~tailwindcss/components.css);

@import url(~tailwindcss/utilities.css);

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

.flex-root{
  flex:1
}

.flex-row-fluid{
  flex:1 auto;
  min-width:0
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .flex-row-fluid{
    min-width:none
  }
}

.flex-column-fluid{
  flex:1 0 auto
}

@media only screen and (max-width: 600px){
  .appLayoutWrapper{
    padding-top:70px
  }
}

@media only screen and (min-width: 600px){
  .appLayoutWrapper{
    padding-top:70px
  }
}

@media only screen and (min-width: 1264px){
  .appLayoutWrapper{
    padding-top:70px
  }
}

.appLayoutWrapper .mainWrapper{
  max-width:1860px;
  width:100%;
  margin-left:auto;
  margin-right:auto
}

@media only screen and (max-width: 600px){
  .appLayoutWrapper .mainWrapper{
    padding:10px 5px
  }
}

@media only screen and (min-width: 600px){
  .appLayoutWrapper .mainWrapper{
    padding:20px 0
  }
}

.appLayoutWrapper .mainWrapper main.app{
  display:flex;
  min-height:100vh
}

@media only screen and (max-width: 1500px){
  .appLayoutWrapper .mainWrapper main.app{
    padding-left:0;
    padding-right:0
  }
}

.appLayoutWrapper .mainWrapper main.app.app_font_size_small{
  font-size:1em
}

.appLayoutWrapper .mainWrapper main.app.app_font_size_medium{
  font-size:1.2em
}

.appLayoutWrapper .mainWrapper main.app.app_font_size_large{
  font-size:1.4em
}

.appLayoutWrapper .mainWrapper main.app .app__sidebar{
  display:flex;
  width:25%
}

@media only screen and (max-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__sidebar{
    width:0
  }
}

@media only screen and (min-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__sidebar{
    width:0
  }
}

@media only screen and (min-width: 1264px){
  .appLayoutWrapper .mainWrapper main.app .app__sidebar{
    display:flex;
    width:25%
  }
}

.appLayoutWrapper .mainWrapper main.app .app__divider{
  display:flex;
  margin-right:15px
}

@media only screen and (max-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__divider{
    display:none
  }
}

@media only screen and (min-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__divider{
    display:none
  }
}

@media only screen and (min-width: 1264px){
  .appLayoutWrapper .mainWrapper main.app .app__divider{
    display:flex
  }
}

@media only screen and (min-width: 1600px){
  .appLayoutWrapper .mainWrapper main.app .app__divider{
    display:flex
  }
}

@media only screen and (max-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__board{
    display:flex;
    width:100%
  }
}

@media only screen and (min-width: 600px){
  .appLayoutWrapper .mainWrapper main.app .app__board{
    display:flex;
    width:100%
  }
}

@media only screen and (min-width: 1264px){
  .appLayoutWrapper .mainWrapper main.app .app__board{
    display:flex;
    width:75%
  }
}

@media only screen and (min-width: 1600px){
  .appLayoutWrapper .mainWrapper main.app .app__board{
    display:flex;
    width:75%
  }
}

.header-mobile{
  background-color:#172b4d
}

@media only screen and (max-width: 600px){
  .header-mobile{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 15px;
    height:55px;
    min-height:55px;
    position:relative;
    z-index:3
  }

  .header-mobile.header-mobile-fixed{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index:97;
    height:55px;
    min-height:55px
  }
}

@media only screen and (min-width: 600px){
  .header-mobile{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 15px;
    height:55px;
    min-height:55px;
    position:relative;
    z-index:3
  }

  .header-mobile.header-mobile-fixed{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index:97;
    height:55px;
    min-height:55px
  }
}

@media only screen and (min-width: 1264px){
  .header-mobile{
    display:none
  }
}

.header-mobile-meun-wrapper{
  z-index:1001;
  position:fixed;
  top:55px;
  bottom:0;
  transition:left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  right:-100%;
  left:auto;
  width:100%;
  background:#fff
}

.header-mobile-meun-wrapper.active{
  right:0
}

.header-mobile-meun-wrapper .menu-nav{
  margin:0;
  list-style-type:none;
  padding-top:10px;
  padding-bottom:10px
}

.header-mobile-meun-wrapper .menu-nav .menu-item{
  padding:0;
  margin:0;
  display:flex;
  flex-direction:column;
  flex-grow:1
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-link{
  padding:9px 30px;
  min-height:44px;
  display:flex;
  text-decoration:none;
  position:relative;
  vertical-align:middle;
  align-items:center;
  outline:0;
  cursor:pointer
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-link .menu-text{
  text-transform:initial;
  display:flex;
  align-items:center;
  flex-grow:1;
  padding:0;
  font-weight:400
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-link .menu-arrow{
  display:flex;
  align-items:center;
  padding-left:10px
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-submenu{
  display:flex;
  flex-grow:1;
  flex-direction:column;
  display:none;
  overflow:hidden
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-submenu.active{
  display:block
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-submenu .menu-subnav{
  transition:background-color 0.3s;
  margin:0
}

.header-mobile-meun-wrapper .menu-nav .menu-item .menu-submenu .menu-subnav .menu-item .menu-link{
  padding:0 30px;
  padding-left:45px
}

.header-menu-wrapper-overlay{
  -webkit-animation:animation-offcanvas-fade-in 0.6s ease 1;
          animation:animation-offcanvas-fade-in 0.6s ease 1;
  background:rgba(0,0,0,0.3);
  top:55px;
  position:fixed;
  left:0;
  bottom:0;
  right:0;
  overflow:hidden;
  z-index:1000
}

@media only screen and (max-width: 600px){
  header.header{
    display:none
  }
}

@media only screen and (min-width: 600px){
  header.header{
    display:none
  }
}

@media only screen and (min-width: 1264px){
  header.header{
    display:flex;
    padding:0 30px;
    height:70px;
    background-color:#172b4d;
    position:relative;
    z-index:2
  }

  header.header.header-fixed{
    position:fixed;
    top:0;
    right:0;
    left:0;
    z-index:97
  }
}

header.header .dropdown{
  position:relative
}

header.header .dropdown .dropdown-menu{
  display:flex;
  flex-direction:column;
  position:absolute;
  right:0;
  background-color:#fff;
  border:1px solid #adadad;
  border-radius:5px;
  opacity:0;
  transition:visibility 0s, opacity 0.3s ease;
  pointer-events:none
}

header.header .dropdown .dropdown-menu .dropdown-item{
  padding:5px 25px;
  text-align:center
}

header.header .dropdown .dropdown-menu .dropdown-item a{
  color:black
}

header.header .dropdown .dropdown-menu .dropdown-item:hover{
  background-color:#e2e2e2
}

header.header .dropdown:hover{
  cursor:pointer
}

header.header .dropdown:hover .dropdown-menu{
  visibility:visible;
  opacity:1;
  pointer-events:all
}

@media only screen and (max-width: 600px){
  .sidebar_wrapper{
    height:100%;
    z-index:999;
    transition:left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    width:100%;
    top:55px;
    left:-100%;
    position:fixed;
    bottom:0;
    background-color:#fff
  }

  .sidebar_wrapper.active{
    left:0px
  }
}

@media only screen and (min-width: 600px){
  .sidebar_wrapper{
    height:100%;
    z-index:999;
    transition:left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    width:60%;
    top:55px;
    left:-100%;
    position:fixed;
    bottom:0;
    background-color:#fff
  }

  .sidebar_wrapper.active{
    left:0px
  }
}

@media only screen and (min-width: 1264px){
  .sidebar_wrapper{
    position:initial;
    top:initial;
    left:initial;
    bottom:initial;
    background:none;
    z-index:initial;
    display:flex;
    width:100%;
    height:100%
  }
}

.sidebar_wrapper .sidebar{
  height:100%;
  width:100%;
  padding:30px;
  border-width:1px;
  border-radius:10px;
  border-color:#718096;
  overflow:hidden;
  box-shadow:3px 3px 6px 0px #E7E7E7;
  background-color:#fff
}

@media only screen and (max-width: 600px){
  .sidebar_wrapper .sidebar{
    box-shadow:none
  }
}

@media only screen and (min-width: 600px){
  .sidebar_wrapper .sidebar{
    box-shadow:none
  }
}

@media only screen and (min-width: 1264px){
  .sidebar_wrapper .sidebar{
    box-shadow:3px 3px 6px 0px #E7E7E7
  }
}

.sidebar_wrapper .sidebar .title{
  font-weight:700;
  font-size:1.5rem
}

.sidebar_wrapper .sidebar .accordion{
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  margin-top:10px;
  margin-bottom:10px;
  border-width:1px;
  border-radius:5px;
  border-color:#707070;
  position:relative
}

.sidebar_wrapper .sidebar .accordion .accordion-btn{
  padding-left:30px;
  padding-right:30px;
  padding-top:10px;
  padding-bottom:10px;
  cursor:pointer;
  font-size:1.25rem;
  display:flex
}

.sidebar_wrapper .sidebar .accordion .accordion-btn svg{
  transition:-webkit-transform 300ms ease-in-out;
  transition:transform 300ms ease-in-out;
  transition:transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out
}

.sidebar_wrapper .sidebar .accordion .accordion-btn.active svg{
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg)
}

.sidebar_wrapper .sidebar .accordion .accordion-meun{
  border-top-width:1px;
  border-color:#707070;
  z-index:10;
  display:none
}

.sidebar_wrapper .sidebar .accordion .accordion-meun.active{
  padding-left:30px;
  padding-right:30px;
  padding-top:30px;
  padding-bottom:30px;
  display:block
}

.sidebar_wrapper .sidebar .accordion .accordion-meun.effect{
  border-top-width:1px;
  border-color:#707070;
  opacity:0;
  max-height:0;
  z-index:10
}

.sidebar_wrapper .sidebar .accordion .accordion-meun.effect.active{
  transition:opacity 300ms ease-in-out, max-height 300ms ease-in-out;
  padding-left:30px;
  padding-right:30px;
  padding-top:30px;
  padding-bottom:30px;
  opacity:1;
  max-height:100%
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-group{
  width:100%;
  display:flex;
  flex-wrap:wrap
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-group .accordion-item:nth-child(1){
  width:100%
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-group .accordion-item:not(:nth-child(1)){
  width:25%
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item{
  display:flex;
  align-items:center
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item label{
  white-space:nowrap
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item input[type='radio']{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  display:inline-block;
  width:1.25rem;
  height:1.25rem;
  border-width:1px;
  border-color:#707070;
  border-radius:9999px;
  cursor:pointer;
  min-width:1.25rem;
  padding:2px;
  margin-right:5px
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item input[type='radio']:checked{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  display:inline-block;
  width:1.25rem;
  min-width:1.25rem;
  height:1.25rem;
  padding:2px;
  margin-right:5px;
  background-clip:content-box;
  border:1px solid #5de6cf;
  background-color:#5de6cf;
  border-radius:50%
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item input[type='radio']:focus{
  outline:none
}

.sidebar_wrapper .sidebar .accordion .accordion-meun .accordion-item+.accordion-item{
  margin-top:5px
}

.sidebar-wrapper-overlay{
  -webkit-animation:animation-offcanvas-fade-in 0.6s ease 1;
          animation:animation-offcanvas-fade-in 0.6s ease 1;
  background:rgba(0,0,0,0.3);
  z-index:998;
  top:55px;
  position:fixed;
  left:0;
  bottom:0;
  right:0;
  overflow:hidden;
  display:none
}

.sidebar-wrapper-overlay.active{
  display:block
}

.board-wrapper{
  display:flex;
  flex-direction:column;
  width:100%;
  height:100%
}

@media only screen and (min-width: 600px){
  .board-wrapper{
    height:100%
  }
}

.board-wrapper .boardTab{
  display:flex
}

.board-wrapper .boardTab__item{
  color:gray;
  width:300px;
  border-top-left-radius:15px;
  border-top-right-radius:15px;
  display:inline-block;
  border-left-width:1px;
  border-right-width:1px;
  border-top-width:1px;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:30px;
  padding-right:30px;
  border-color:#718096;
  text-align:center;
  font-weight:700;
  cursor:pointer;
  transition-duration:300ms;
  background-color:#fff
}

.board-wrapper .boardTab__item.--active{
  box-shadow:6px -3px 6px 0px #e7e7e7;
  color:#000
}

@media only screen and (max-width: 600px){
  .board-wrapper .boardTab__item{
    width:50%;
    font-size:0.8rem;
    padding:10px
  }
}

@media only screen and (min-width: 600px){
  .board-wrapper .boardTab__item{
    font-size:1.25rem
  }
}

.board-wrapper .board{
  display:flex;
  flex-direction:column;
  height:100%;
  padding:30px;
  border-width:1px;
  border-color:#718096;
  border-radius:10px;
  border-top-left-radius:0;
  box-shadow:3px 3px 6px 0px #E7E7E7;
  background-color:#fff
}

@media only screen and (max-width: 600px){
  .board-wrapper .board{
    padding:10px;
    border-top-right-radius:0
  }
}

.board-wrapper .board__footer{
  display:flex;
  justify-content:space-between;
  margin-top:20px
}

@media only screen and (max-width: 600px){
  .board-wrapper .board__footer{
    flex-direction:column
  }

  .board-wrapper .board__footer__paginationArea{
    display:flex;
    justify-content:center;
    margin-top:20px
  }
}

.board-wrapper .board__footer__downloadArea{
  display:flex;
  justify-content:center
}

.searchBar{
  border-color:#707070;
  border-width:1px;
  padding-left:30px;
  padding-right:30px;
  padding-top:15px;
  padding-bottom:15px;
  display:flex;
  flex-direction:column;
  justify-content:space-between
}

.searchBar>div>div:not(:first-child){
  margin-left:10px
}

.searchBar .searchBarRow{
  display:flex
}

@media only screen and (min-width: 600px){
  .searchBar .searchBarRow{
    flex-direction:column
  }
}

@media only screen and (min-width: 1600px){
  .searchBar .searchBarRow{
    flex-direction:row
  }
}

.searchBar .searchBarRow__grid{
  display:flex;
  flex-direction:column;
  height:100%
}

@media only screen and (min-width: 600px){
  .searchBar .searchBarRow__grid{
    width:100%
  }
}

@media only screen and (min-width: 1600px){
  .searchBar .searchBarRow__grid{
    width:50%
  }
}

.searchBar .searchBarRow__grid__row{
  display:flex;
  align-items:center;
  height:100%
}

.searchBar .searchBarRow__grid__row:nth-child(2){
  margin-top:10px
}

.searchBar .row1{
  display:flex;
  justify-content:space-between
}

.searchBar .row1>div:not(:first-child){
  margin-left:10px
}

.searchBar .row2{
  display:flex;
  justify-content:space-between;
  margin-top:10px
}

.searchBar .row2>div>div:not(:first-child){
  margin-left:10px
}

@media only screen and (max-width: 600px){
  .searchBar{
    display:none
  }
}

@media only screen and (min-width: 600px){
  .searchBar{
    display:none
  }
}

@media only screen and (min-width: 1264px){
  .searchBar{
    display:flex
  }
}

.searchBarMobile{
  display:flex;
  flex-direction:column
}

@media only screen and (min-width: 1264px){
  .searchBarMobile{
    display:none
  }
}

.searchBarMobile__searchGroup__concordance{
  display:flex;
  flex-direction:column
}

.searchBarMobile__searchGroup__concordance__input{
  border-color:#ddd;
  width:100%;
  padding:2px 10px;
  border-width:1px;
  border-radius:5px
}

@media only screen and (max-width: 600px){
  .searchBarMobile__searchGroup__concordance__input{
    padding-top:0;
    padding-bottom:0;
    margin-right:10px
  }
}

.searchBarMobile__searchGroup__concordance__btn{
  width:100%;
  font-size:0.875rem;
  background-color:#5DE6CF;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  color:#fff;
  border-radius:5px;
  transition-duration:300ms
}

.searchBarMobile__searchGroup__concordance__btn:hover{
  background-color:#22C2A7
}

@media only screen and (max-width: 600px){
  .searchBarMobile__searchGroup__concordance__btn{
    font-size:0.875rem;
    margin-left:0;
    padding-left:20px;
    padding-right:20px
  }
}

.searchBarMobile__searchGroup__concordance__btn--disable{
  width:100%;
  font-size:0.875rem;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  color:#fff;
  border-radius:5px;
  transition-duration:300ms;
  background-color:#cbd5e0;
  cursor:not-allowed
}

.searchBarMobile__searchGroup__wordlist{
  display:flex;
  flex-direction:column
}

.searchBarMobile__searchGroup__wordlist__input{
  border-color:#ddd;
  width:100%;
  padding:2px 10px;
  border-width:1px;
  border-radius:5px
}

@media only screen and (max-width: 600px){
  .searchBarMobile__searchGroup__wordlist__input{
    padding-top:0;
    padding-bottom:0;
    margin-right:10px
  }
}

.searchBarMobile__searchGroup__wordlist__btn{
  font-size:0.875rem;
  width:100%;
  background-color:#5DE6CF;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  color:#fff;
  border-radius:5px;
  transition-duration:300ms
}

.searchBarMobile__searchGroup__wordlist__btn:hover{
  background-color:#22C2A7
}

.searchBarMobile__searchGroup__wordlist__btn--disable{
  font-size:0.875rem;
  width:100%;
  background-color:#cbd5e0;
  text-align:center;
  padding-left:10px;
  padding-right:10px;
  padding-top:5px;
  padding-bottom:5px;
  color:#fff;
  border-radius:5px;
  transition-duration:300ms;
  cursor:not-allowed
}

@media only screen and (max-width: 600px){
  .searchBarMobile__searchGroup__wordlist__btn{
    font-size:0.875rem;
    margin-left:0;
    padding-left:20px;
    padding-right:20px
  }
}

.kwicControlWrapper{
  display:flex;
  justify-content:flex-end;
  align-items:center;
  width:100%
}

@media only screen and (max-width: 600px){
  .kwicControlWrapper{
    flex-direction:column;
    flex-wrap:wrap
  }
}

.kwicControlWrapper .kwicControl{
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:10px
}

@media only screen and (max-width: 600px){
  .kwicControlWrapper .kwicControl{
    margin-top:5px;
    margin-left:0;
    width:100%;
    justify-content:space-between
  }
}

.kwicControlWrapper .kwicControl .kwicControl__label{
  margin-left:5px
}

@media only screen and (max-width: 600px){
  .kwicControlWrapper .kwicControl .kwicControl__label{
    font-size:0.875rem
  }
}

.kwicControlWrapper .kwicControl .kwicControl__input{
  width:120px;
  display:flex;
  justify-content:space-between;
  margin-left:10px;
  border-style:none;
  text-align:center;
  border-radius:5px
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn{
  display:flex;
  justify-content:center;
  align-items:center;
  width:30px;
  padding-top:5px;
  padding-bottom:5px;
  transition-duration:300ms;
  cursor:pointer;
  color:#fff
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.left{
  border-top-left-radius:5px;
  border-bottom-left-radius:5px
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.right{
  border-top-right-radius:5px;
  border-bottom-right-radius:5px
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.default{
  background-color:#ddd
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.lv1{
  background-color:red
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.lv2{
  background-color:#18a000
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.lv3{
  background-color:#f700ff
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn.disabled{
  background-color:#cbd5e0;
  pointer-events:none
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn:hover svg{
  color:#000
}

@media only screen and (max-width: 600px){
  .kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__btn{
    font-size:0.875rem
  }
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num{
  width:60px;
  font-size:1.125rem;
  border-top-width:1px;
  border-bottom-width:1px;
  display:flex;
  justify-content:center;
  align-items:center
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num.default{
  border-color:#ddd
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num.lv1{
  border-color:red
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num.lv2{
  border-color:#18a000
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num.lv3{
  border-color:#f700ff
}

.kwicControlWrapper .kwicControl .kwicControl__input .kwicControl__input__num.disabled{
  border-color:#cbd5e0;
  pointer-events:none
}

.kwicControlWrapper .level_numbox{
  border-color:black;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:5px;
  border-width:1px;
  border-radius:5px;
  text-align:center
}

.kwicControlWrapper .level_numbox .side_buttons{
  display:flex;
  flex-direction:column;
  justify-content:space-between
}

.kwicControlWrapper .level_numbox .side_buttons .side_button{
  width:10px;
  height:5px;
  background-color:red
}

.kwicControlWrapper .level_numbox .the_option{
  width:100px
}

.level_dropdown{
  display:flex;
  align-items:center;
  z-index:10
}

.level_dropdown label{
  white-space:nowrap
}

.level_dropdown .custom_dropdown{
  display:flex;
  flex-direction:column;
  position:relative;
  cursor:pointer;
  width:100%
}

.level_dropdown .custom_dropdown .the_option{
  border-color:#ddd;
  padding:5px 25px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  border-width:1px;
  border-radius:5px;
  text-align:center;
  position:relative;
  width:100%
}

.level_dropdown .custom_dropdown .options{
  border-color:#ddd;
  background:#fff;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  margin-top:5px;
  border-width:1px;
  border-radius:5px;
  text-align:center;
  display:none;
  width:100%
}

.level_dropdown .custom_dropdown .options .option{
  padding:0px 25px;
  display:block;
  cursor:pointer;
  white-space:nowrap
}

.level_dropdown .custom_dropdown .options .option:hover{
  background-color:#d3d3d3;
  transition:background-color 300ms ease
}

.level_dropdown .custom_dropdown .options .option input{
  display:none
}

.level_dropdown .custom_dropdown .options .option label{
  padding-top:5px;
  padding-bottom:5px;
  cursor:pointer;
  display:flex;
  justify-content:center
}

.level_dropdown .custom_dropdown .svg{
  position:absolute;
  right:10px;
  width:8px;
  height:16px;
  transition:-webkit-transform 300ms ease-in-out;
  transition:transform 300ms ease-in-out;
  transition:transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out
}

@media only screen and (max-width: 600px){
  .level_dropdown .custom_dropdown .svg{
    top:8px
  }
}

@media only screen and (min-width: 600px){
  .level_dropdown .custom_dropdown .svg{
    top:12px
  }
}

.level_dropdown .custom_dropdown.active .the_option{
  border-bottom-color:white;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0
}

.level_dropdown .custom_dropdown.active .options{
  border-top-color:white;
  display:block;
  position:absolute;
  top:30px;
  border-top-left-radius:0;
  border-top-right-radius:0
}

.level_dropdown .custom_dropdown.active .svg{
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
  -webkit-transform-origin:center;
          transform-origin:center
}

.serach_input{
  border-color:#ddd;
  width:500px;
  padding:2px 10px;
  border-width:1px;
  border-radius:5px
}

@media only screen and (max-width: 600px){
  .serach_input{
    width:70%;
    padding-top:0;
    padding-bottom:0;
    margin-right:10px
  }
}

@media only screen and (max-width: 600px){
  .resultTableSimpleBarWrapper{
    height:100%
  }
}

@media only screen and (min-width: 600px){
  .resultTableSimpleBarWrapper{
    height:100%
  }
}

.selectChipSetWrapper{
  display:flex;
  align-items:center
}

@media only screen and (max-width: 600px){
  .selectChipSetWrapper{
    display:none
  }
}

@media only screen and (min-width: 600px){
  .selectChipSetWrapper{
    display:flex
  }
}

.resultBoard{
  width:100%;
  height:100%;
  border-radius:10px;
  border-color:#E7E7E7;
  box-shadow:0px 3px 6px 0px #E7E7E7
}

.resultBoard__tableWrapper{
  width:100%;
  height:100%
}

.resultBoard .loader-placeholder{
  position:relative;
  overflow:hidden;
  background-color:#e2e8f0
}

.resultBoard .loader-placeholder::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  -webkit-transform:translateX(-100%);
          transform:translateX(-100%);
  background-image:linear-gradient(90deg, rgba(255,255,255,0) 0, rgba(255,255,255,0.2) 20%, rgba(255,255,255,0.5) 60%, rgba(255,255,255,0));
  -webkit-animation:shimmer 3s infinite;
          animation:shimmer 3s infinite;
  content:''
}

@-webkit-keyframes shimmer{
  100%{
    -webkit-transform:translateX(100%);
            transform:translateX(100%)
  }
}

@keyframes shimmer{
  100%{
    -webkit-transform:translateX(100%);
            transform:translateX(100%)
  }
}

.pagination{
  display:flex;
  align-items:center
}

.pagination .item{
  border-color:rgba(136,152,170,0.5);
  width:35px;
  height:35px;
  border-width:1px;
  border-radius:9999px;
  display:flex;
  justify-content:center;
  align-items:center;
  transition-duration:300ms;
  cursor:pointer
}

@media only screen and (max-width: 600px){
  .pagination .item{
    width:20px;
    height:20px
  }
}

@media only screen and (min-width: 600px){
  .pagination .item{
    width:35px;
    height:35px
  }
}

.pagination .item .link svg{
  color:rgba(136,152,170,0.5)
}

@media only screen and (max-width: 600px){
  .pagination .item .link{
    font-size:0.75rem
  }
}

@media only screen and (min-width: 600px){
  .pagination .item .link{
    font-size:0.875rem
  }
}

.pagination .item:hover{
  background-color:#5DE6CF;
  color:#fff;
  border-style:none;
  box-shadow:0px 3px 6px 0px #E7E7E7
}

.pagination .item:hover .link svg{
  color:#fff
}

.pagination .item.active{
  background-color:#5DE6CF;
  color:#fff;
  border-style:none;
  box-shadow:0px 3px 6px 0px #E7E7E7
}

.pagination .item.disabled{
  cursor:not-allowed;
  pointer-events:none
}

@media only screen and (max-width: 600px){
  .pagination .item+.item{
    margin-left:5px
  }
}

@media only screen and (min-width: 600px){
  .pagination .item+.item{
    margin-left:10px
  }
}

.pagination .input-item{
  display:flex;
  align-items:center;
  margin-left:10px;
  margin-right:10px
}

.pagination .input-item .input{
  width:60px;
  border-color:rgba(136,152,170,0.5);
  border-width:1px;
  text-align:center;
  border-radius:0.5rem;
  padding-top:1px;
  padding-bottom:1px
}

@media only screen and (max-width: 600px){
  .pagination .input-item .input{
    width:50px;
    font-size:0.875rem
  }
}

.transcribedFileWrapper{
  width:100%;
  display:flex;
  padding-left:2.5rem;
  padding-right:2.5rem
}

@media only screen and (max-width: 600px){
  .transcribedFileWrapper__fileList{
    height:100%;
    z-index:999;
    transition:left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    width:100%;
    top:55px;
    left:-100%;
    position:fixed;
    bottom:0;
    background-color:#fff
  }

  .transcribedFileWrapper__fileList.active{
    left:0px
  }
}

@media only screen and (min-width: 600px){
  .transcribedFileWrapper__fileList{
    height:100%;
    z-index:999;
    transition:left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    width:60%;
    top:55px;
    left:-100%;
    position:fixed;
    bottom:0;
    background-color:#fff
  }

  .transcribedFileWrapper__fileList.active{
    left:0px
  }
}

@media only screen and (min-width: 1264px){
  .transcribedFileWrapper__fileList{
    position:initial;
    top:initial;
    left:initial;
    bottom:initial;
    background:none;
    z-index:initial;
    width:33.333333%;
    height:100%
  }
}

@media only screen and (max-width: 600px){
  .transcribedFileWrapper__fileContent{
    width:100%;
    height:100%
  }
}

@media only screen and (min-width: 600px){
  .transcribedFileWrapper__fileContent{
    width:100%;
    height:100%
  }
}

@media only screen and (min-width: 1264px){
  .transcribedFileWrapper__fileContent{
    width:66.666667%;
    height:100%
  }
}

.search_bar_btn{
  background-color:#22C2A7;
  text-align:center;
  margin-left:10px;
  padding-left:15px;
  padding-right:15px;
  padding-top:5px;
  padding-bottom:5px;
  height:100%;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:100ms;
  width:auto
}

.search_bar_btn:hover{
  background-color:#5DE6CF
}

@media only screen and (max-width: 600px){
  .search_bar_btn{
    font-size:0.875rem;
    margin-left:0;
    padding-left:20px;
    padding-right:20px
  }
}

.search_bar_btn--disable{
  text-align:center;
  margin-left:10px;
  padding-left:15px;
  padding-right:15px;
  padding-top:5px;
  padding-bottom:5px;
  height:100%;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:100ms;
  width:auto;
  background-color:#cbd5e0;
  cursor:not-allowed
}

.sort_btn{
  background-color:#5DE6CF;
  text-align:center;
  margin-left:10px;
  padding-left:15px;
  padding-right:15px;
  padding-top:5px;
  padding-bottom:5px;
  height:100%;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:100ms;
  width:auto
}

.sort_btn:hover{
  background-color:#22C2A7
}

@media only screen and (max-width: 600px){
  .sort_btn{
    font-size:0.875rem;
    margin-left:0;
    padding-left:20px;
    padding-right:20px
  }
}

.sort_btn--disable{
  text-align:center;
  margin-left:10px;
  padding-left:15px;
  padding-right:15px;
  padding-top:5px;
  padding-bottom:5px;
  height:100%;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:100ms;
  width:auto;
  background-color:#cbd5e0;
  cursor:not-allowed
}

@media only screen and (max-width: 600px){
  .sort_btn--disable{
    font-size:0.875rem;
    margin-left:0;
    padding-left:20px;
    padding-right:20px
  }
}

.text_input_horizontal input{
  border-color:#ddd;
  width:100px;
  padding:2px 10px;
  margin-left:5px;
  border-width:1px;
  border-radius:5px
}

@media only screen and (max-width: 600px){
  .text_input_horizontal label{
    font-size:0.875rem
  }

  .text_input_horizontal input{
    height:30px;
    font-size:0.875rem;
    width:auto;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:5px;
    padding-right:5px
  }
}

.inputHorizontal--mobile{
  display:flex;
  justify-content:space-between;
  align-items:center
}

.inputHorizontal--mobile__label{
  font-size:0.875rem;
  text-align:center
}

.inputHorizontal--mobile__input{
  border-color:#ddd;
  height:30px;
  margin-left:5px;
  border-width:1px;
  border-radius:5px;
  font-size:0.875rem;
  width:auto;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:5px;
  padding-right:5px
}

.numberInputHorizontal--mobile{
  display:flex;
  justify-content:space-between;
  align-items:center
}

.numberInputHorizontal--mobile__label{
  font-size:1rem;
  text-align:center
}

.numberInputHorizontal--mobile__input{
  border-color:#ddd;
  margin-left:5px;
  border-width:1px;
  border-radius:5px;
  font-size:1rem;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:5px;
  padding-right:5px
}

@media only screen and (max-width: 600px){
  .numberInputHorizontal--mobile__input{
    height:30px;
    width:100px
  }
}

@media only screen and (min-width: 600px){
  .numberInputHorizontal--mobile__input{
    height:35px;
    width:110px
  }
}

.cell_checkbox{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  display:inline-block;
  width:1.25rem;
  height:1.25rem;
  border-width:1px;
  border-color:#707070;
  border-radius:5px;
  cursor:pointer;
  min-width:1.25rem;
  margin-right:5px
}

.cell_checkbox:checked{
  display:inline-block;
  width:1.25rem;
  min-width:1.25rem;
  height:1.25rem;
  margin-right:5px;
  background-clip:content-box;
  border:1px solid #5de6cf;
  background-color:#5de6cf;
  border-radius:5px;
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-size:12px;
  background-repeat:no-repeat;
  background-position:center
}

.cell_checkbox:focus{
  outline:none
}

.result_table_wrapper{
  width:100%
}

.result_table_wrapper table.result_table{
  width:100%
}

@media only screen and (max-width: 600px){
  .result_table_wrapper table.result_table{
    font-size:0.875rem
  }
}

@media only screen and (min-width: 600px){
  .result_table_wrapper table.result_table{
    font-size:1.25rem
  }
}

.result_table_wrapper table.result_table thead{
  background-color:#f1f3f9
}

.result_table_wrapper table.result_table thead td{
  color:#8898aa;
  vertical-align:middle;
  font-weight:400;
  text-align:center
}

.result_table_wrapper table.result_table thead td.id{
  padding-top:10px;
  padding-bottom:10px;
  padding-left:20px;
  padding-right:20px
}

.result_table_wrapper table.result_table thead td.sidetext{
  max-width:460px;
  overflow:hidden;
  white-space:nowrap;
  padding-top:10px;
  padding-bottom:10px;
  padding-left:10px;
  padding-right:10px
}

.result_table_wrapper table.result_table thead td.centertext{
  padding-top:10px;
  padding-bottom:10px;
  padding-left:5px;
  padding-right:5px
}

.result_table_wrapper table.result_table tbody tr:nth-of-type(odd){
  background-color:#f7fafc
}

.result_table_wrapper table.result_table tbody td{
  text-align:center
}

.result_table_wrapper table.result_table tbody td.id{
  padding-top:10px;
  padding-bottom:10px
}

.result_table_wrapper table.result_table tbody td.sidetext{
  max-width:460px;
  overflow:hidden;
  white-space:nowrap;
  padding-top:10px;
  padding-bottom:10px
}

.result_table_wrapper table.result_table tbody td.centertext{
  padding-top:10px;
  padding-bottom:10px
}

.result_table_wrapper table.result_table tbody td .lv0{
  color:#0059ff
}

.result_table_wrapper table.result_table tbody td .lv1{
  color:red
}

.result_table_wrapper table.result_table tbody td .lv2{
  color:#18a000
}

.result_table_wrapper table.result_table tbody td .lv3{
  color:#f700ff
}

.result_table_wrapper table.result_table tbody td .lv4{
  color:#ffc800
}

.loader{
  background-color:#fff;
  display:flex;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
  align-items:center;
  justify-content:center;
  width:100vw
}

.loader span{
  position:absolute;
  border-radius:9999px;
  background-color:#146F60;
  height:4rem;
  width:4rem;
  -webkit-animation:pulse 1s ease-in-out infinite;
          animation:pulse 1s ease-in-out infinite;
  -webkit-transform:translate(-50%, -50%) scale(0);
          transform:translate(-50%, -50%) scale(0)
}

.loader .loader_img{
  -webkit-animation:filp 2s alternate infinite ease-in-out;
          animation:filp 2s alternate infinite ease-in-out;
  position:absolute;
  border-radius:9999px;
  height:4rem;
  width:4rem
}

@-webkit-keyframes filp{
  0%{
    -webkit-transform:rotateX(0deg) rotateY(0deg);
            transform:rotateX(0deg) rotateY(0deg)
  }

  50%{
    -webkit-transform:rotateX(0deg) rotateY(180deg);
            transform:rotateX(0deg) rotateY(180deg)
  }

  100%{
    -webkit-transform:rotateX(180deg) rotateY(180deg);
            transform:rotateX(180deg) rotateY(180deg)
  }
}

@keyframes filp{
  0%{
    -webkit-transform:rotateX(0deg) rotateY(0deg);
            transform:rotateX(0deg) rotateY(0deg)
  }

  50%{
    -webkit-transform:rotateX(0deg) rotateY(180deg);
            transform:rotateX(0deg) rotateY(180deg)
  }

  100%{
    -webkit-transform:rotateX(180deg) rotateY(180deg);
            transform:rotateX(180deg) rotateY(180deg)
  }
}

.modal{
  transition:opacity 0.25s ease
}

.modal3 form label{
  color:#3182ce;
  letter-spacing:0.025em
}

.modal3 form label.required::after{
  content:' *';
  color:#e53e3e
}

.modal3 form input.form-control{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  width:100%;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
  font-size:0.875rem;
  border-bottom-width:1px;
  border-color:#8d8d8d;
  background-color:#f3f3f3
}

.modal3 form input.form-control::-webkit-input-placeholder{
  font-size:0.875rem;
  color:#b5b5b5
}

.modal3 form input.form-control:-ms-input-placeholder{
  font-size:0.875rem;
  color:#b5b5b5
}

.modal3 form input.form-control::-ms-input-placeholder{
  font-size:0.875rem;
  color:#b5b5b5
}

.modal3 form input.form-control::placeholder{
  font-size:0.875rem;
  color:#b5b5b5
}

.modal3 form input.form-control:focus{
  border-color:#5DE6CF;
  outline:0
}

.modal3 form input.form-control.is-invalid{
  border-color:#f56565
}

.modal3--submit-btn{
  background-color:#5DE6CF;
  text-align:center;
  width:100%;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  font-size:1rem
}

.modal3--submit-btn--disable{
  background-color:#cbd5e0;
  text-align:center;
  width:100%;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  font-size:1rem;
  pointer-events:none
}

.modal3--submit-btn:hover{
  background-color:#22C2A7
}

.modal3--submit-btn:focus{
  border:none
}

@-webkit-keyframes bounce{
  to{
    opacity:0.3;
    -webkit-transform:translate3d(0, -1rem, 0);
            transform:translate3d(0, -1rem, 0)
  }
}

@keyframes bounce{
  to{
    opacity:0.3;
    -webkit-transform:translate3d(0, -1rem, 0);
            transform:translate3d(0, -1rem, 0)
  }
}

.kwicSpinner{
  display:flex;
  justify-content:center;
  align-items:center;
  height:100%
}

.kwicSpinner div{
  width:1rem;
  height:1rem;
  margin:2rem 0.3rem;
  background:#5de6cf;
  border-radius:50%;
  -webkit-animation:0.7s bounce infinite alternate;
          animation:0.7s bounce infinite alternate
}

.kwicSpinner div:nth-child(2){
  -webkit-animation-delay:0.2s;
          animation-delay:0.2s
}

.kwicSpinner div:nth-child(3){
  -webkit-animation-delay:0.4s;
          animation-delay:0.4s
}

.kwicSpinner div:nth-child(4){
  -webkit-animation-delay:0.6s;
          animation-delay:0.6s
}

.kwicSpinner div:nth-child(5){
  -webkit-animation-delay:0.8s;
          animation-delay:0.8s
}

html,body{
  height:100%;
  font-size:16px;
  font-family:'Rubik', 'Helvetica', 'Arial', sans-serif;
  font-weight:400;
  -ms-text-size-adjust:100%;
  background:#ffffff;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

body{
  display:flex;
  flex-direction:column;
  color:#3f4254;
  height:100%
}

#root{
  height:100%
}

main.landing{
  background-image:url("../images/front_page/header-background.jpg")
}

main.landing .svg_set{
  position:relative
}

main.landing .svg_set .main{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%)
}

@media only screen and (max-width: 600px){
  main.landing .svg_set .main img{
    width:200px
  }
}

@media only screen and (min-width: 600px){
  main.landing .svg_set .main img{
    width:300px
  }
}

@media only screen and (min-width: 1264px){
  main.landing .svg_set .main img{
    width:400px
  }
}

main.landing .svg_set .result{
  position:absolute;
  top:50%;
  left:65%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%)
}

@media only screen and (max-width: 600px){
  main.landing .svg_set .result img{
    width:100px
  }
}

@media only screen and (min-width: 600px){
  main.landing .svg_set .result img{
    width:150px
  }
}

@media only screen and (min-width: 1264px){
  main.landing .svg_set .result img{
    width:200px
  }
}

main.landing .svg_set .sub{
  position:absolute;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%)
}

@media only screen and (max-width: 600px){
  main.landing .svg_set .sub img{
    width:80px
  }
}

@media only screen and (min-width: 600px){
  main.landing .svg_set .sub img{
    width:120px
  }
}

@media only screen and (min-width: 1264px){
  main.landing .svg_set .sub img{
    width:150px
  }
}

main.landing .svg_set .sub.tl{
  top:30%;
  left:30%
}

main.landing .svg_set .sub.tl .point{
  -webkit-animation:float3 50s linear infinite;
          animation:float3 50s linear infinite
}

main.landing .svg_set .sub.tr{
  top:30%;
  right:10%
}

main.landing .svg_set .sub.tr .point{
  -webkit-animation:float4 50s linear infinite;
          animation:float4 50s linear infinite
}

main.landing .svg_set .sub.bl{
  bottom:15%;
  left:30%
}

main.landing .svg_set .sub.bl .point{
  -webkit-animation:float5 50s linear infinite;
          animation:float5 50s linear infinite
}

main.landing .svg_set .sub.br{
  bottom:15%;
  right:10%
}

main.landing .svg_set .sub.br .point{
  -webkit-animation:float6 50s linear infinite;
          animation:float6 50s linear infinite
}

@-webkit-keyframes float1{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@keyframes float1{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@-webkit-keyframes float2{
  from{
    -webkit-transform:rotate(360deg) translate(-25px) rotate(-360deg);
            transform:rotate(360deg) translate(-25px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-25px) rotate(0deg);
            transform:rotate(0deg) translate(-25px) rotate(0deg)
  }
}

@keyframes float2{
  from{
    -webkit-transform:rotate(360deg) translate(-25px) rotate(-360deg);
            transform:rotate(360deg) translate(-25px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-25px) rotate(0deg);
            transform:rotate(0deg) translate(-25px) rotate(0deg)
  }
}

@-webkit-keyframes float3{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@keyframes float3{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@-webkit-keyframes float4{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@keyframes float4{
  from{
    -webkit-transform:rotate(360deg) translate(-20px) rotate(-360deg);
            transform:rotate(360deg) translate(-20px) rotate(-360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-20px) rotate(0deg);
            transform:rotate(0deg) translate(-20px) rotate(0deg)
  }
}

@-webkit-keyframes float5{
  from{
    -webkit-transform:rotate(-360deg) translate(-25px) rotate(360deg);
            transform:rotate(-360deg) translate(-25px) rotate(360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-25px) rotate(0deg);
            transform:rotate(0deg) translate(-25px) rotate(0deg)
  }
}

@keyframes float5{
  from{
    -webkit-transform:rotate(-360deg) translate(-25px) rotate(360deg);
            transform:rotate(-360deg) translate(-25px) rotate(360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-25px) rotate(0deg);
            transform:rotate(0deg) translate(-25px) rotate(0deg)
  }
}

@-webkit-keyframes float6{
  from{
    -webkit-transform:rotate(0deg) translate(-15px) rotate(0deg);
            transform:rotate(0deg) translate(-15px) rotate(0deg)
  }

  to{
    -webkit-transform:rotate(360deg) translate(-15px) rotate(-360deg);
            transform:rotate(360deg) translate(-15px) rotate(-360deg)
  }
}

@keyframes float6{
  from{
    -webkit-transform:rotate(0deg) translate(-15px) rotate(0deg);
            transform:rotate(0deg) translate(-15px) rotate(0deg)
  }

  to{
    -webkit-transform:rotate(360deg) translate(-15px) rotate(-360deg);
            transform:rotate(360deg) translate(-15px) rotate(-360deg)
  }
}

@-webkit-keyframes float7{
  from{
    -webkit-transform:rotate(-360deg) translate(-35px) rotate(360deg);
            transform:rotate(-360deg) translate(-35px) rotate(360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-35px) rotate(0deg);
            transform:rotate(0deg) translate(-35px) rotate(0deg)
  }
}

@keyframes float7{
  from{
    -webkit-transform:rotate(-360deg) translate(-35px) rotate(360deg);
            transform:rotate(-360deg) translate(-35px) rotate(360deg)
  }

  to{
    -webkit-transform:rotate(0deg) translate(-35px) rotate(0deg);
            transform:rotate(0deg) translate(-35px) rotate(0deg)
  }
}

section.reg form label.required::after{
  content:' *';
  color:#e53e3e
}

section.reg form input.form-control,section.reg form textarea.form-control{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  width:100%;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
  font-size:0.875rem;
  border-bottom-width:1px;
  border-color:#8d8d8d;
  background-color:#f3f3f3
}

section.reg form input.form-control::-webkit-input-placeholder, section.reg form textarea.form-control::-webkit-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

section.reg form input.form-control:-ms-input-placeholder, section.reg form textarea.form-control:-ms-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

section.reg form input.form-control::-ms-input-placeholder, section.reg form textarea.form-control::-ms-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

section.reg form input.form-control::placeholder,section.reg form textarea.form-control::placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

section.reg form input.form-control:focus,section.reg form textarea.form-control:focus{
  border-color:#5DE6CF;
  outline:0
}

section.reg form input.form-control.is-invalid,section.reg form textarea.form-control.is-invalid{
  border-color:#f56565
}

section.reg .submit-btn{
  background-color:#5DE6CF;
  text-align:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:300ms
}

section.reg .submit-btn--disable{
  background-color:#cbd5e0;
  text-align:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:300ms;
  pointer-events:none
}

section.reg .submit-btn:hover{
  background-color:#22C2A7
}

section.reg .submit-btn:focus{
  border:none
}

.auth{
  position:relative;
  background-color:#172b4d;
  height:100vh;
  width:100vw
}

.auth .rect{
  height:55vh;
  background-color:#5de6cf;
  position:absolute;
  bottom:0;
  width:100vw
}

.auth .rect::before{
  content:'';
  position:absolute;
  top:-25vh;
  width:0;
  height:0;
  border-left:100vw solid transparent;
  border-bottom:25vh solid #5de6cf
}

.auth form label.required::after{
  content:' *';
  color:#e53e3e
}

.auth form input.form-control,.auth form textarea.form-control{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  width:100%;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-left:0.75rem;
  padding-right:0.75rem;
  font-size:0.875rem;
  border-bottom-width:1px;
  border-color:#8d8d8d;
  background-color:#f3f3f3
}

.auth form input.form-control::-webkit-input-placeholder, .auth form textarea.form-control::-webkit-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

.auth form input.form-control:-ms-input-placeholder, .auth form textarea.form-control:-ms-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

.auth form input.form-control::-ms-input-placeholder, .auth form textarea.form-control::-ms-input-placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

.auth form input.form-control::placeholder,.auth form textarea.form-control::placeholder{
  font-size:0.875rem;
  color:#8d8d8d
}

.auth form input.form-control:focus,.auth form textarea.form-control:focus{
  border-color:#5DE6CF;
  outline:0
}

.auth form input.form-control.is-invalid,.auth form textarea.form-control.is-invalid{
  border-color:#f56565
}

.auth .submit-btn{
  background-color:#5DE6CF;
  text-align:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:300ms
}

.auth .submit-btn--disable{
  background-color:#cbd5e0;
  text-align:center;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  color:#fff;
  border-radius:0.25rem;
  transition-duration:300ms;
  pointer-events:none
}

.auth .submit-btn:hover{
  background-color:#22C2A7
}

.auth .submit-btn:focus{
  border:none
}

.auth .back-to-login{
  position:absolute;
  top:5%;
  color:white;
  transition:color 300ms;
  font-size:1.25rem
}

.auth .back-to-login:hover{
  color:#172b4d
}

@media only screen and (max-width: 600px){
  .auth .back-to-login{
    left:20px
  }
}

@media only screen and (min-width: 600px){
  .auth .back-to-login{
    left:20px
  }
}

@media only screen and (min-width: 1264px){
  .auth .back-to-login{
    left:140px
  }
}

.auth .register-success-modal{
  top:30%;
  position:absolute;
  transition-duration:500ms;
  z-index:20;
  width:25%;
  min-height:70%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  background-color:#fff;
  padding-left:5rem;
  padding-right:5rem;
  padding-top:3rem;
  padding-bottom:3rem;
  border-radius:0.5rem
}

.auth .register-success-modal.active{
  top:15%
}
